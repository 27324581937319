// import logo from './logo.svg';
import './App.css';
import { withAuthenticator } from '@aws-amplify/ui-react'
import { AmplifyAuthenticator, AmplifySignOut } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import { Auth } from 'aws-amplify';
import awsconfig from "./aws-exports"
import React from 'react';
import './classification.css';
import Amplify, { API } from 'aws-amplify';

// import logo from './logo.png'; // with import
class classify extends React.Component {
                    constructor(props) {
                    super(props);
                    this.state = {
//
                        url: " ",


                    };
                    this.myChangeHandler = this.myChangeHandler.bind(this);
                    this.mySubmitHandler  = this.mySubmitHandler .bind(this);
                    }





                   mySignoutHandler = async (event) => {
                        try {
                            await Auth.signOut();
                            alert("Signed out")
                        } catch (error) {
                            console.log('error signing out: ', error);
                        }
                    }


                    mySubmitHandler = async (event) => {
                    event.preventDefault();

                    fetch(' https://api-classification.craft.co/classify', {
                            method: 'POST', // *GET, POST, PUT, DELETE, etc.

                            crossDomain:true,
                            headers: {

                                // 'x-api-key': process.env.API_URL,
                                    'authorizationToken': `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
                                    'Content-Type':'application/json',
                                    //'x-api-key': Secret,
                                    'Accept': '*/*',
                                    'Content-Type': 'text/plain'
                                },
                            // We convert the React state to JSON and send it as the POST body
                            body: JSON.stringify(this.state.url)
                        })
                        .then((response)=> response.json())
                        .then((data) => {
                            console.log('Success:', data);
                        alert("The classification template has been added to your notion page")
                        this.setState({url: " "});
                        })
                        .catch((error) => {
                            console.error('Error:', error);
                             alert("Error please try again or contact the platform team")
                        });


                            }


                        myChangeHandler = (event) => {
                            this.setState({url: event.target.value});
                        };


                        render() {

                        return (
                        <div className="main-block">


                            <form id="classificationForm" onSubmit={this.mySubmitHandler} ref={this.form}>

                            <p>Enter your Notion Page's URL</p>
                            <input
                            type='url'
                            value={this.state.url}
                            onChange={this.myChangeHandler}
                            />



                                <br></br>
                                <br></br>
                                <button type="submit">Submit</button>
                                <br></br>

                             <button type="button" onClick={this.mySignoutHandler}>Sign Out</button>

                            </form>

                        </div>
                        );
                        }
                    }

            export default withAuthenticator(classify)
            //    export default classify


// <select>
//                                 <option value="Public" >Public</option>
//                                 <option value="Internal">Internal</option>
//                                 <option value="Restricted">Restricted</option>
//                                 <option value="Confidential">Confidential</option>
//                                 </select>
//                                 <br></br>
//                                 <br></br>
//     <p  style={{color:"black"}}>Classification</p>
