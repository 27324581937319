/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_wGbsKqEuR",
    "aws_user_pools_web_client_id": "3ptpccj6lkecve8jf8i36s557v",
    "oauth": {
        "domain": "craft-classification-tool.auth.us-east-1.amazoncognito.com",
        "scope": [
            "email",
            "openid",
            "profile"
        ],
        "redirectSignIn": "https://classification.craft.co",
        "redirectSignOut": "https://classification.craft.co",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
};


export default awsmobile;
